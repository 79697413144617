import { I18n } from '@front/volcanion'

class Callbacks {
  static resetPasswordHandler(openNotification, handleResetPasswordAction) {
    return async function resetPassword(state, extra,) {
      const { method, user_id } = state
      try {
        await handleResetPasswordAction({ user_id, channel: 'driver', adapter: method, application: null, user_type: 'driver' })
        openNotification(I18n.t('password.reset.success', { method }), { variant: 'success' })
      } catch (err) {
        openNotification(I18n.t('password.reset.error', { method }), { variant: 'error' })
      }
    }
  }
  static onCallDriverHandler(handleCall, gsm, openNotification) {
    return async function onCallDriver() {
      try {
        await handleCall(gsm)
      } catch (err) {
        openNotification(I18n.t('telephony.error'), { variant: 'error' })
      }
    }
  }
}


export default Callbacks
