import React from 'react'

import { hooks } from '@front/volcanion'
import { Loader } from '@front/squirtle'

const withContainer = Component => props => {
  const isReady = hooks.useFormStatus('isReady')
  const [group_id] = hooks.useFormState('group_id')
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')
  const [, { openDialog }] = hooks.useDialogs()

  const authorization = hooks.useCurrentAuthorization()
  const canTransfer = authorization?.modify
  const mergedProps = {
    isManagingRecord,
    group_id,
    openDialog,
    canTransfer
  }

  return (
    <Loader isLoading={!isReady}>
      <Component {...mergedProps} />
    </Loader>
  )
}

export default withContainer
