import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => ({ form_name, goBackPath = null, closeOnBack = false, ...props }) => {
  const navigate = hooks.useNavigate()
  const requestSubmit = hooks.useGlobalFormFunction(form_name, 'requestSubmit')
  const [, setReadOnly] = hooks.useGlobalFormState(form_name, 'isReadOnly')
  const [isManagingRecord] = hooks.useGlobalFormState(form_name, 'isManagingRecord')
  const isFormMounted = hooks.useGlobalFormStatus(form_name, 'isMounted')

  const authorization = hooks.useCurrentAuthorization()
  const onEdit = useCallback(e => {
    setReadOnly(false)
    e.stopPropagation()
  }, [setReadOnly])

  const onSave = useCallback(e => {
    requestSubmit()
    e.stopPropagation()
  }, [requestSubmit])

  const onBack = useCallback(e => {
    !!closeOnBack && window.close()
    navigate(goBackPath || -1)
    e.stopPropagation()
  }, [navigate, closeOnBack, goBackPath])

  const onCancel = useCallback(e => {
    !isManagingRecord && onBack()
    !!isManagingRecord && setReadOnly(true)
    e.stopPropagation()
  }, [setReadOnly, isManagingRecord, onBack])


  const mergedProps = {
    isFormMounted,
    isManagingRecord,
    requestSubmit,
    onEdit,
    onSave,
    onCancel,
    onBack,
    canCreate: authorization?.create,
    canModify: authorization?.modify
  }

  return <Component {...mergedProps} {...props} />
}

export default withContainer
