import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Typography, Icon } from '@mui/material'

import AssociatedInfoComponent from '@abra/elements/event/Data'

const BillingEventParser = ({ events }) => {
  return _.map(_.filter(events, ['type', 'billing']), ({ event_id, name, data }) => (
    <Box key={event_id} sx={{ display: 'flex' }}>
      <Icon color='primary' fontSize={'small'}>{'circle'}</Icon>
      <Box>
        <Typography sx={{ fontSize: 13, ml: 1, color: 'text.label' }}>
          {[I18n.t(`event.${name}`), _.isEmpty(data) ? '' : ': '].join('')}
        </Typography>
      </Box>
      <Box><AssociatedInfoComponent data={data} /></Box>
    </Box>
  ))
}

export default BillingEventParser