import React from 'react'
import { I18n, hooks } from '@front/volcanion'

import { TableCell, Icon, Box } from '@mui/material'
import { TextCell } from '@abra/elements/table/cells/basics/'
import { DriverLink } from '@abra/elements'
import AssociatedInfoComponent from '@abra/elements/event/Data/'

import FormatUtils from '@front/squirtle/utils/format'
import _ from 'lodash'

const Row = ({ row }) => {
  const [{ name: producer_name }] = hooks.useCustomerInfo(row?.produced_by?.user_id)

  const rowStyle = { size: 'small', sx: { paddingTop: 0.5, paddingRight: 0.5 } }
  const textStyle = { fontSize: 13, textAlign: 'left' }
  const formattedEventName = I18n.t(`event.${row?.name}`)
  const producer_info = _.compact([I18n.t(`event.keys.${row?.producer_usertype}`), producer_name]).join(': ')
  const billing_status_color = hooks.useBillingStatusColor({ order_id: row?.order })

  return <>
    <TableCell {...rowStyle}>
      <TextCell {...textStyle} value={FormatUtils.formatDateBackToFront(_.get(row, 'producedAt'), 'DD/MM HH:mm:ss')} />
    </TableCell>
    <TableCell {...rowStyle}>
      <TextCell {...textStyle} value={producer_info} />
    </TableCell>
    <TableCell {...rowStyle}>
      <DriverLink {...textStyle} record_id={row?.driver?.user_id} hidden={!row?.driver} />
    </TableCell>
    <TableCell  {...rowStyle}>
      <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {row?.type === 'billing' && <Icon sx={{ marginRight: 0.75, color: billing_status_color }} >{'verified'}</Icon>}
        <TextCell {...textStyle} value={formattedEventName} />
      </Box>
    </TableCell>
    <TableCell {...rowStyle}>
      <AssociatedInfoComponent {...textStyle} data={row?.data || []} action={row?.action} />
    </TableCell>
  </>

}

export default React.memo(Row)
