import { I18n } from '@front/volcanion'

class Callbacks {
  static getChartDataHandler(data, colors, schedule_type) {
    return function getChartData() {
      const complete_order_ca = _.sumBy(_.map(_.filter(data, (elem) => elem.status === 'complete' && elem.schedule_type === 'planned'), 'COUNT'));
      const complete_order_ci = _.sumBy(_.map(_.filter(data, (elem) => elem.status === 'complete' && elem.schedule_type === 'immediate'), 'COUNT'));
      const cancel_order_ca = _.sumBy(_.map(_.filter(data, (elem) => elem.status === 'canceled' && elem.schedule_type === 'planned'), 'COUNT'));
      const cancel_order_ci = _.sumBy(_.map(_.filter(data, (elem) => elem.status === 'canceled' && elem.schedule_type === 'immediate'), 'COUNT'));
      const not_loaded = _.sumBy(_.map(_.filter(data, (elem) => elem.reason === 'not_loaded'), 'COUNT'));
      const not_loaded_ci = _.sumBy(_.map(_.filter(data, (elem) => elem.reason === 'not_loaded' && elem.schedule_type === 'immediate'), 'COUNT'));
      const not_loaded_ca = _.sumBy(_.map(_.filter(data, (elem) => elem.reason === 'not_loaded' && elem.schedule_type === 'planned'), 'COUNT'));
      const complete_order = _.sumBy(_.map(_.filter(data, ['status', 'complete']), 'COUNT'));
      const cancel_order = _.sum([_.sumBy(_.map(_.filter(data, (elem) => elem.status === 'canceled'), 'COUNT'))]);

      return ({
        labels: _.compact([
          _.compact([
            I18n.t('dashboard.order.complete'),
            I18n.t('dashboard.order.cancel'),
            !!schedule_type && I18n.t('dashboard.order.not_loaded')
          ]),
          !schedule_type ? [
            I18n.t('dashboard.order.complete_ca'),
            I18n.t('dashboard.order.complete_ci'),
            I18n.t('dashboard.order.cancel_ca'),
            I18n.t('dashboard.order.cancel_ci'),
            I18n.t('dashboard.order.not_loaded')
          ] : false
        ]),
        datasets: _.compact([
          {
            data: _.flatten([
              !schedule_type ? [complete_order, _.sum([cancel_order, not_loaded])] : [],
              schedule_type === 'immediate' ? [complete_order_ci, cancel_order_ci, not_loaded_ci] : [],
              schedule_type === 'planned' ? [complete_order_ca, cancel_order_ca, not_loaded_ca] : []
            ]),
            backgroundColor: [colors?.primary[600], colors?.error[600], colors?.gray[400]],
            borderWidth: 0.5
          },
          !schedule_type && {
            data: [
              complete_order_ca,
              complete_order_ci,
              cancel_order_ca,
              cancel_order_ci,
              not_loaded
            ],
            backgroundColor: [colors?.primary[400], colors?.primary[200], colors?.error[400], colors?.error[200], colors?.gray[400]],
            borderWidth: 0.5
          }
        ]),
        total: _.sum(_.flatten(_.compact([
          !schedule_type && [complete_order, cancel_order, not_loaded],
          schedule_type === 'immediate' && [complete_order_ci, cancel_order_ci, not_loaded_ci],
          schedule_type === 'planned' && [complete_order_ca, cancel_order_ca, not_loaded_ca]
        ])))
      });
    }
  }
}

export default Callbacks
