import React from 'react'
import { hooks } from '@front/volcanion'

import { Loader } from '@front/squirtle'

const withContainer = Component => props => {
  const [record_id] = hooks.useFormState('record_id')
  const isReady = hooks.useFormStatus('isReady')
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')
  const [license] = hooks.useModel('license', [record_id], { populate: ['usual_vehicle.group'], single: true })
  const [, { openDialog }] = hooks.useDialogs()
  const authorization = hooks.useCurrentAuthorization()
  const canModifyRelay = authorization?.modify
  const mergedProps = {
    isManagingRecord,
    openDialog,
    hasRelayVehicle: license?.usual_vehicle?.group?.type === 'relay',
    canModifyRelay
  }

  return (
    <Loader isLoading={!isReady}>
      <Component {...mergedProps} {...props} />
    </Loader>
  )
}

export default withContainer
