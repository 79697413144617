import React, { useState, useEffect } from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => ({ record_id, ...rest }) => {
  const { isLoading } = hooks.useSearchListenerStatus('order', 'order_validation_list')

  const [openOrder, setOpenOrder] = useState(false)
  const [openBilling, setOpenBilling] = useState(true)
  const [openHistory, setOpenHistory] = useState(false)

  useEffect(() => {
    if (!!record_id) {
      setOpenOrder(false)
      setOpenBilling(true)
    } else {
      setOpenOrder(false)
      setOpenBilling(false)
    }
  }, [record_id])

  const mergedProps = {
    record_id,
    isLoading,
    openOrder,
    openBilling,
    openHistory,
    setOpenOrder,
    setOpenBilling,
    setOpenHistory
  }

  return <Component {...mergedProps} />
}

export default withContainer
