import React from 'react'

import { MapFactory } from '@front/squirtle'


const HomePage = (props) => {
  return (
    <MapFactory.MapContainer className='full-screen-map' mapId='mainScreen'>
    </MapFactory.MapContainer>
  )
}

export default React.memo(HomePage)
