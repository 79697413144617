import React from 'react'
import { I18n, hooks } from '@front/volcanion'

import { Box, TableCell, Icon } from '@mui/material'
import { TextCell } from '@abra/elements/table/cells/basics/'

import FormatUtils from '@front/squirtle/utils/format'
import BillingEventParser from '../../EventParser'

const Row = ({ row }) => {

  const billing_status = _.get(row, 'billing_status')
  const billing_status_color = hooks.useBillingStatusColor(row)

  return <>
    <TableCell size={'small'}>
      <Icon color='primary'>
        {!!_.get(row, 'service') ? 'business_center' : 'person'}
      </Icon>
    </TableCell>
    <TableCell size={'small'}>
      <TextCell value={_.get(row, 'order_client_id')} sx={{ color: 'primary.main', fontSize: 14, fontWeight: 600, whiteSpace: 'nowrap' }} />
    </TableCell>
    <TableCell size={'small'} >
      <Box sx={{ display: 'flex' }}>
        <Icon color='primary' fontSize={'small'} sx={{ mr: 1 }}>
          {_.get(row, 'schedule_type') === 'planned' ? 'calendar_month' : 'download'}
        </Icon>
        <TextCell value={FormatUtils.formatDateBackToFront(_.get(row, 'requestedAt'))} />
      </Box>
    </TableCell>
    <TableCell size={'small'}>
      <TextCell value={!!billing_status && I18n.t(`billing.status.${billing_status}`)} sx={{ color: billing_status_color, fontWeight: 600 }} />
    </TableCell>
    <TableCell size={'small'}>
      <BillingEventParser events={row?.events} />
    </TableCell>
  </ >
}

export default React.memo(Row)
