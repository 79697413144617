import React, { useEffect } from "react"

import { hooks } from "@front/volcanion"
import { HookUtils } from "@front/volcanion/utils"
import { Loader } from "@front/squirtle"

import NoResultComponent from "../noResult"

const withContainer = Component => props => {
  const [{ company }] = hooks.useFormState()
  const [contract, service, user, date_start, date_end, schedule_type] = hooks.useFieldValues(['dashboard_contract', 'dashboard_service', 'user', 'date_start', 'date_end', 'schedule_type'])

  const [data, search, state] = hooks.useModelValues('order', 'get', {})

  const default_filter = {
    requestedAt: { '>=': date_start, '<=': date_end },
    service: (!!service && { companyservice_id: service }) || { contract: (!!contract && { companycontract_id: contract }) || { company } },
    do: user || undefined,
    schedule_type: schedule_type || undefined
  }
  const filter = {
    or: [
      //COMMENT keep both syntax, it's used to be retro-compatible, before reason was a string now it's a model
      _.merge({}, default_filter, { reason: 'not_loaded' }),
      _.merge({}, default_filter, { reason: { identifier: 'not_loaded' } }),
      _.merge({}, default_filter, { status: { '!': 'canceled' } }),
      _.merge({}, default_filter, { reason: { '!': { identifier: 'not_loaded' } }, status: 'canceled' }),
      _.merge({}, default_filter, { reason: { '!': 'not_loaded' }, status: 'canceled' })
    ]
  }

  const options = {
    group_by: ['DATE(requestedAt) AS x', 'status AS status', 'reason.identifier AS reason', 'schedule_type'],
    no_select: true,
    query_operations: [{ path: 'order_id', operation: 'COUNT', alias: 'y' }],
    sort: ['DATE(requestedAt) DESC'],
    select: [],
    populate: ['reason'],
    limit: -1,
    type: 'b2b'
  }

  useEffect(() => {
    search({ filter, options })
  }, [contract, service, user, date_start, date_end, schedule_type])

  const mergedProps = {
    data,
    date_end,
    date_start
  }

  return (
    <Loader isLoading={HookUtils.getLoadingState([state])} size={100} >
      {_.isEmpty(data) ? <NoResultComponent /> : <Component {...mergedProps} />}
    </Loader>
  )
}

export default withContainer
