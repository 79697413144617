import ValidationUtils from '@front/volcanion/utils/validation'

class Callbacks {
  static setUserInfoHandler(setFormValues, setUserID) {
    return function setUserInfo(user, ignored_fields, extra_updates) {
      const updates = _.compact(_.flatten([
        { field: 'contact.first_name', value: _.get(user, 'info.first_name') },
        { field: 'contact.last_name', value: _.get(user, 'info.last_name') },
        { field: 'contact.gsm', value: _.get(user, 'auth.gsm') },
        { field: 'user.auth.application', value: _.get(user, 'auth.application') },
        { field: 'user.user_id', value: _.get(user, 'user_id') },
        extra_updates
      ]))
      const final_updates = _.filter(updates, (update) => !_.includes(ignored_fields, _.get(update, 'field')))
      setFormValues(final_updates)
      setUserID(_.get(user, 'user_id'))
    }
  }
  static onUsersFoundHandler(setUserInfo, user_id, application_id) {
    return function onUsersFound(users, source) {
      if (users.length === 1)
        setUserInfo(_.head(users))
      else {
        const matched_user = _.find(users, ['auth.application', application_id])
        if (!!matched_user) setUserInfo(matched_user)
        else if (!!user_id) setUserInfo(null, [source])
      }
    }
  }
  static onFieldClearHandler(setUserInfo, clearUsers) {
    return function onFieldClear() {
      clearUsers()
      setUserInfo(null)
    }
  }
  static searchGSMHandler(searchUser, onFieldClear, onUsersFound, application) {
    return async function searchGSM(gsm) {
      if (_.isEmpty(gsm))
        onFieldClear()
      else {
        const users = await searchUser({ auth: { gsm, application } })
        await onUsersFound(users, 'user.gsm')
      }
    }
  }
  static normalizePhoneHandler(CODE_INTERNATIONAL) {
    return function normalizePhone(value) {
      return ValidationUtils.parsePhone(value, CODE_INTERNATIONAL)
    }
  }
}

export default Callbacks
