import React, { useCallback } from 'react'

import { hooks } from '@front/volcanion'
import { Loader } from '@front/squirtle'

const withContainer = Component => props => {
  const isReady = hooks.useFormStatus('isReady')
  const [user_id] = hooks.useFormState('user_id')
  const [company] = hooks.useFormState('company')

  const handleClickClientDetail = useCallback(() => {
    !!user_id && window.open(`/client/${user_id}`, '_blank')
  }, [user_id])

  const mergedProps = {
    handleClickClientDetail,
    company,
  }

  return <Loader isLoading={!isReady}>
    <Component {...mergedProps} {...props} />
  </Loader>
}

export default withContainer
