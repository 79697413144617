import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'
import { useApplication as useMainApplication } from '@abra/hooks/application'
import Callbacks from './callbacks'
import { HookUtils } from '@front/volcanion/utils'
import { Loader } from '@front/squirtle'


const withContainer = Component => props => {
  const setFormValues = hooks.useFormChange()
  const [record_id] = hooks.useFormState('record_id')
  const [, setUserID] = hooks.useFormState('user_id')
  const [defaultCountry, , country_state] = hooks.useSetting('country_code')
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')
  const default_application = _.get(useMainApplication(), 'application_id')
  const [users, searchUser, , { clear: clearUsers }] = hooks.useModelSearch('user', 'get', { populate: ['auth', 'info', 'customerinfo'] })

  const user_id = _.get(_.head(users), 'user_id')

  const setUserInfo = useCallback(Callbacks.setUserInfoHandler(setFormValues, setUserID), [setFormValues, setUserID])
  const onFieldClear = useCallback(Callbacks.onFieldClearHandler(setUserInfo, clearUsers), [setUserInfo, clearUsers])
  const onUsersFound = useCallback(Callbacks.onUsersFoundHandler(setUserInfo, user_id, default_application), [setUserInfo, user_id, default_application])
  const searchGSM = useCallback(Callbacks.searchGSMHandler(searchUser, onFieldClear, onUsersFound, default_application), [searchUser, onFieldClear, onUsersFound, default_application])

  const mergedProps = {
    record_id,
    isManagingRecord,
    user_id,
    defaultCountry,
    searchGSM,
  }
  return (
    <Loader isLoading={HookUtils.getLoadingState([country_state])}>
      <Component {...mergedProps} />
    </Loader>
  )
}
export default withContainer
