import { I18n } from '@front/volcanion'
class Callbacks {
  static statusChangeHandler(openDialog, setSuspendedState) {
    return async function statusChange(value) {
      if (value === 'suspended')
        openDialog('suspended')
      else
        setSuspendedState({ name: value })
    }
  }

  static onUploadPhotoHandler(upload, getHasPhoto) {
    return async function onUploadPhoto(event) {
      try {
        await upload(event)
        await getHasPhoto()
      }
      catch (err) {
        console.warn("Error upload photo", { err })
      }
    }
  }

  static onConfirmDeletePhotoHandler(openDialog, canModifyImage) {
    return function onConfirmDeletePhoto() {
      if (canModifyImage) {
        openDialog('confirm_delete_photo', {
          title: I18n.t('driver.popup.delete_photo.title'),
          description: I18n.t('driver.popup.delete_photo.description'),
        })
      }
    }
  }

  static onDeletePhotoHandler(deletePhoto, getHasPhoto) {
    return async function onDeletePhoto() {
      try {
        await deletePhoto()
        await getHasPhoto()
      }
      catch (err) {
        console.warn("Error delete photo", { err })
      }
    }
  }

}

export default Callbacks
