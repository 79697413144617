import React from 'react'

import { Badge, IconButton, Box, Grid, Icon } from '@mui/material'

const DEFAULT_BUTTON_PROPS = { sx: { bgcolor: 'primary.main', fontSize: 'inherit', color: 'inherit', boxShadow: 6 } }

const OrderDetails = props => {
  const {
    ordersCount,
    jobOrdersCount,
    handleClickClientDetail,
    handleClickClientHistory,
    handleClickOrderJob,
    hasJob,
    hasUser,
    disableHeader,
    defaultIconButtonProps = DEFAULT_BUTTON_PROPS,
    canDisplayClient,
    canDisplayJob
  } = props

  return (
    <Box sx={{ color: 'white', mt: disableHeader ? 13 : 19, fontSize: 21 }}>
      <Grid container rowSpacing={3} justifyContent='flex-end'>
        <Grid item xs={8} hidden={!hasUser}>
          <Badge badgeContent={ordersCount} color="error" sx={{ m: 0 }}>
            <IconButton onClick={handleClickClientHistory} {...defaultIconButtonProps}>
              <Icon fontSize='inherit'> {'timeline'} </Icon>
            </IconButton>
          </Badge>
        </Grid>
        {canDisplayClient && (
          <Grid item xs={8} hidden={!hasUser}>
            <IconButton onClick={(handleClickClientDetail)} {...defaultIconButtonProps}>
              <Icon fontSize='inherit'> {'account_circle'} </Icon>
            </IconButton>
          </Grid>
        )}
        {canDisplayJob && (
          <Grid item xs={8} hidden={!hasJob}>
            <Badge badgeContent={jobOrdersCount} color="error" sx={{ m: 0 }}>
              <IconButton onClick={handleClickOrderJob} {...defaultIconButtonProps}>
                <Icon fontSize='inherit'> {'local_taxi'} </Icon>
              </IconButton>
            </Badge>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default React.memo(OrderDetails)
