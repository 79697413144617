import React, { useCallback } from 'react'
import { hooks, ModelForm } from '@front/volcanion'

import Callbacks from './callbacks'

const withForm = Component => props => {
  const navigate = hooks.useNavigate()
  const [, { openDialog }] = hooks.useDialogs()

  const formProps = {
    name: 'broadcast_form',
    model_name: 'broadcast',
    getEmptyForm: useCallback(Callbacks.getEmptyFormHandler(), []),
    formToRecord: useCallback(Callbacks.formToRecordHandler(), []),
    populate: [],
    initialState: { isReadOnly: false },
    onSubmitSuccess: useCallback(Callbacks.onSubmitSuccessHandler(navigate), [navigate]),
    onSubmitFailed: useCallback(Callbacks.onSubmitFailedHandler(), []),
    beforeSubmit: useCallback(Callbacks.beforeSubmitHanbdler(openDialog), [openDialog]),
  }

  const mergedProps = {
    goBackPath: '/message'
  }

  return (
    <ModelForm {...formProps}>
      <Component {...mergedProps} />
    </ModelForm>
  )
}

export default withForm
