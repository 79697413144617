import React from 'react'
import { I18n, CDNImage } from '@front/volcanion/'

import { Grid, Box, StepLabel, StepContent, IconButton, Icon } from '@mui/material'

import { DateField, TextField, MenuSelectorField, SingleOptionSelectorField, Loader, ConfirmationDialog } from '@front/squirtle'
import { SuspensionTitleForm } from '@abra/elements/Suspensions'

import CloudUploadIcon from '@mui/icons-material/CloudUpload'


const Rows = ({ isManagingRecord, statusColor, statusChange, verifyClientId }) =>
  <Grid container spacing={2} rowSpacing={5}>
    <Grid item xs={3}>
      <TextField name='info.user_client_id' label={I18n.t('driver.code')} debounceTime={250} disabled={!!isManagingRecord} afterUserChange={verifyClientId} />
    </Grid>
    <Grid item xs={3}>
      <TextField name='info.last_name' label={I18n.t('name.label', { count: 1 })} required />
    </Grid>
    <Grid item xs={3}>
      <TextField name='info.first_name' label={I18n.t('name.first_name', { count: 1 })} required />
    </Grid>
    <Grid item xs>
      <MenuSelectorField
        label={I18n.t('gender.label')}
        name='info.gender'
        options={_.compact([
          { label: I18n.t('gender.female'), value: 'F' },
          { label: I18n.t('gender.male'), value: 'M' },
        ])}
        noneLabel={I18n.t('gender.unknow')}
      />
    </Grid>
    <Grid item xs={3} hidden={!isManagingRecord}>
      <TextField name='score.average_rating' label={I18n.t('evaluation.note', { count: 1 })} disabled />
    </Grid>
    <Grid item xs={3} hidden={!isManagingRecord}>
      {!!isManagingRecord && <DateField
        name={'info.createdAt'}
        label={I18n.t('date.create')}
        inputFormat={'DD/MM/YYYY'}
        disabled
        disableOpenPicker
      />}
    </Grid>
    <Grid item xs={3} hidden={!isManagingRecord}>
      {!!isManagingRecord && <DateField
        name={'info.updatedAt'}
        label={I18n.t('date.updatedAt')}
        inputFormat={'DD/MM/YYYY HH:mm'}
        mask={'__/__/____ __:__'}
        disabled
        disableOpenPicker
      />}
    </Grid>
    <Grid item xs={2}>
      <MenuSelectorField
        label={I18n.t('status.label', { count: 1 })}
        name={'status'}
        options={_.compact([
          { label: I18n.t('status.active'), value: 'active' },
          { label: I18n.t('status.deleted'), value: 'deleted', disabled: !isManagingRecord },
          { label: I18n.t('status.suspended'), value: 'suspended', disabled: !isManagingRecord }
        ])}
        valueDecoratorProps={{
          sx: { WebkitTextFillColor: statusColor, fontWeight: 600 }
        }}
        selectFirst
        required
        afterChange={statusChange}
      />
    </Grid>
    <Grid item xs={2}>
      <SingleOptionSelectorField
        name={'driverinfo.can_call_booker'}
        label={I18n.t('call.all_client')}
        clearOnEmpty={false}
      />
    </Grid>
  </Grid>


const Side1 = (props) => {
  const {
    photoURL,
    hasPhoto,
    driverCDN,
    onDeletePhoto,
    onConfirmDeletePhoto,
    hasPhotoState,
    onUploadPhoto,
    canModifyImage
  } = props || {}

  return (
    <>
      <ConfirmationDialog
        name={'confirm_delete_photo'}
        onConfirm={onDeletePhoto}
      />
      <Loader isLoading={hasPhotoState?.isLoading || (!!hasPhoto && !photoURL)} size={30} >
        {!!hasPhoto ?
          <IconButton
            onClick={onConfirmDeletePhoto}
            startIcon={<CloudUploadIcon />}
          >
            <img src={photoURL} width={150} height={150} style={{ borderRadius: '50%' }} />
            {canModifyImage && <Icon variant={'outlined'} style={{ position: 'absolute', top: 10, right: 10, backgroundColor: 'grey' }}> {'clear'}</Icon>}
          </IconButton>
          : <IconButton
            component="label"
            startIcon={<CloudUploadIcon />}
          >
            {canModifyImage && (
              <input
                type="file"
                accept=".jpg, .jpeg"
                id="faceImage"
                hidden
                onChange={onUploadPhoto}
              />
            )}
            <CDNImage name={driverCDN} category='images' style={{ height: 150, width: 150 }} />
            {canModifyImage && <Icon variant={'outlined'} style={{ position: 'absolute', top: 10, right: 10, backgroundColor: 'grey' }}> {'edit'}</Icon>}
          </IconButton>
        }
      </Loader >
    </>
  )
}


const PrimarySection = props => {
  const {
    user_auth_id,
    isManagingRecord,
    statusColor,
    statusChange,
    verifyClientId,
    isReadOnly
  } = props || {}


  return (
    <>
      <StepLabel>
        <SuspensionTitleForm
          label={I18n.t('info.general')}
          record_id={user_auth_id}
        />
      </StepLabel>
      <StepContent>
        <Box sx={{ p: 4, boxShadow: 6, borderRadius: 3 }}>
          <Grid container sx={{ flexDirection: 'row' }}>
            <Grid xs={10} >
              <Rows isManagingRecord={isManagingRecord} statusColor={statusColor} statusChange={statusChange} verifyClientId={verifyClientId} />
            </Grid>
            <Grid xs={2} sx={{ display: isReadOnly ? '' : 'none' }}>
              <Side1 {...props} />
            </Grid>
          </Grid>
        </Box>
      </StepContent >
    </>
  )
}
export default React.memo(PrimarySection)
