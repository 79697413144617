import React from 'react'

import { Grid, Step, Button } from '@mui/material'
import { GridStepper } from '@front/squirtle'
import { I18n } from '@front/volcanion'

import FormHeader, { Actions } from '@abra/elements/headers/Form'
import GroupTab from '@abra/panels/Tab/Group'

import PrimarySection from './Section/Primary'
import OptionsSection from './Section/Options'
import HistorySection from './Section/History'
import Popup from './Popup'

const Vehicle = ({ isManagingRecord, group_id, openDialog, canTransfer }) =>
  <Grid container item xs={10} justifyContent='center' rowSpacing={3}>
    <Grid item xs={12} hidden={!group_id}> <GroupTab value={3} /> </Grid>
    <Grid item xs={12}>
      <Popup />
      <FormHeader>
        {canTransfer && (
          <Actions>
            <Button variant='contained' onClick={() => openDialog('transfer_vehicle_popup')} >
              {I18n.t('vehicle.transfer')}
            </Button>
          </Actions>
        )}
      </FormHeader>
      <GridStepper groupsCount={[3]} activeStep={0} orientation="vertical" getStepProps={index => ({ active: index !== 2 || !!isManagingRecord })}>
        <Step> <PrimarySection /> </Step>
        <Step> <OptionsSection /> </Step>
        <Step> <HistorySection disabled={!isManagingRecord} /> </Step>
      </GridStepper>
    </Grid>
  </Grid>

export default React.memo(Vehicle)
