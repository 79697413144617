import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Grid, Typography } from '@mui/material'
import { TextField, OptionSelectorField } from '@front/squirtle'

const Row1 = props =>
  <Grid container spacing={2} xs={12} justifyContent='center' alignItems='center' >
    <Grid item xs={3}>
      <TextField name='operator.info.last_name' label={I18n.t('operator', { count: 1 })} />
    </Grid>
    <Grid item xs={6}>
      <OptionSelectorField
        name='name'
        label={I18n.t('type.label', { count: 1 })}
        options={[
          { label: I18n.t('export.name.export'), value: 'export' },
          { label: I18n.t('export.name.export_billing'), value: 'export_billing' },
          { label: I18n.t('export.name.export_job'), value: 'export_job' },
          { label: I18n.t('export.name.export_license'), value: 'export_license' }
        ]}
      />
    </Grid>
  </Grid>

const Form = props =>
  <Box sx={{ boxShadow: 6, borderRdisu: 1, p: 2 }}>
    <Grid container rowSpacing={3}>
      <Grid item xs={9}>
        <Typography variant={'h5'}> {I18n.t('export.label', { count: -1 })} </Typography>
      </Grid>
      <Row1 {...props} />
    </Grid>
  </Box>

export default React.memo(Form)
