class Callbacks {
  static handleClickClientDetailHandler(user_id) {
    return function handleClickClientDetail() {
      if (!user_id) return
      window.open(`/client/${user_id}`, '_blank')
    }
  }
  static handleClickClientHistoryHandler(gsm) {
    return function handleClickClientHistory() {
      if (!gsm) return
      window.open(`/order?client_phone=${_.replace(gsm, '+', '')}`, '_blank')
    }
  }
  static handleClickOrderJobHandler(job_id) {
    return function handleClickOrderJob() {
      if (!job_id) return
      window.open(`/job/${job_id}`, '_blank')
    }
  }
}

export default Callbacks
